(function(i, p, w, d, b, $, t){
    var TNT_Wallpaper_Ad = {
        'el': {
            'ad_container': '#tn-wallpaper-container',
            'ad_grid': '.tn-wallpaper-grid',
            'ad_grid_buffer': '.tn-wallpaper-grid-buffer',
            'ad_grid_item': '.tn-wallpaper-grid-item'
        },
        'ad_html': function() {
            var html = '',
                maxWidth = 1170;
            if (this.settings.page_width == '1366') maxWidth = 1366;
            html += '<style>' +
                this.el.ad_container +' { position: fixed; top: 0; left: 0; width: 100%; height: 100vh; }' +
                this.el.ad_grid + ' { display: -ms-grid; display: grid; -ms-grid-rows: 100vh; grid-template-rows: 100vh; }' +
                '@media (min-width: 768px) { ' + this.el.ad_grid + ' { -ms-grid-columns: 1fr 750px 1fr; grid-template-columns: 1fr 750px 1fr; } }' +
                '@media (min-width: 992px) { ' + this.el.ad_grid + ' { -ms-grid-columns: 1fr 970px 1fr; grid-template-columns: 1fr 970px 1fr; } }' +
                '@media (min-width: 1200px) { ' + this.el.ad_grid + ' { -ms-grid-columns: 1fr 1170px 1fr; grid-template-columns: 1fr 1170px 1fr; } }' +
                '@media (min-width:' + (maxWidth + 30) +'px) { ' + this.el.ad_grid + ' { -ms-grid-columns: 1fr ' + maxWidth + 'px 1fr; grid-template-columns: 1fr ' + maxWidth + 'px 1fr; } }' +
                this.el.ad_grid_buffer + ' { -ms-grid-column: 2; -ms-grid-row: 1; }' +
                this.el.ad_grid_item + '.left { display: block; -ms-grid-column: 1; -ms-grid-row: 1; background: url(' + this.settings.background_left + ') no-repeat; background-size: auto; }' +
                this.el.ad_grid_item + '.right { display: block; -ms-grid-column: 3; -ms-grid-row: 1; background: url(' + this.settings.background_right + ') no-repeat; background-size: auto; }' +
                '#site-container { padding: 0!important; }' +
                '.drawer-open ' + this.el.ad_container + ', .modal-open ' + this.el.ad_container + '{ display: none; }' +
                '.modal-open #site-container { position: static; }' +
                '.drawer-open #site-container { position: static; width: auto; padding: 0; margin: 0; }';
                if (this.settings.clipping === 'Outside') {
                    html += this.el.ad_grid_item + '.left { background-position: top right; }' +
                    this.el.ad_grid_item + '.right { background-position: top left; }';
                } else if (this.settings.clipping === 'Inside') {
                    html += this.el.ad_grid_item + '.left { background-position: top left; }' +
                    this.el.ad_grid_item + '.right { background-position: top right; }';
                }
                if (this.settings.background_color) {
                    html += this.el.ad_grid_item + '.left, ' + this.el.ad_grid_item + '.right { background-color: #' + this.settings.background_color.replace('#','') + '; }';
                }
            html += '</style>' +
            '<div id="' + this.el.ad_container.replace('#','') + '" class="hidden-xs">' +
                '<div class="' + this.el.ad_grid.replace('.','') + '">' +
                    '<a href="' + this.settings.click_tag + '" class="' + this.el.ad_grid_item.replace('.','') +' left" target="_blank" rel="noopener"></a>' +
                    '<div class="' + this.el.ad_grid_buffer.replace('.','') + '"></div>' +
                    '<a href="' + this.settings.click_tag + '" class="' + this.el.ad_grid_item.replace('.','') + ' right" target="_blank" rel="noopener"></a>' +
                '</div>' +
            '</div>';
            if (this.settings.tracking_pixel) {
                html += '<div style="display:none;"><img src="' + this.settings.tracking_pixel + '" height="1" width="1" /></div>';
            }
            return html;
        },
        'load': function(settings) {
            this.settings = settings;

            $(d).find(p).hide();
            $(b).find('#site-container').addClass('container has-wallpaper');
            $(b).prepend(this.ad_html());
        },
    };

    try {
        if (!i) {
            throw 'Friendly iframe required.';
        }
        $(t.cmds).each(function() {
            TNT_Wallpaper_Ad.load(this.call());
            return false;
        });
    }
    catch(e){
        if (w.console) {
            w.console.warn(e);
        }
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.inDapIF ? window.parent.jQuery : jQuery),
    (window.__TNT_AD || {})
);